<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
     <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="$router.push('/SystemOffer/add')">添加商家</el-button>
      </div>
    </GlobalForm>
  </div>
</template>

<script>
// import _api from "@/utils/request";
export default {
  name: 'Form',
  data() {
    return {
      merchantName:"",
      initData: null,
      formItemList: [
        {
          key: 'staffName',
          type: 'input',
          labelName: '商家名称',
          placeholder: '请输入商家名称'
        },
        {slot:"AddSlot"}
      ],
      formRules: {
        staffName: []
      }
    }
  },
  methods: {
    handleConfirm(data,cd) {
      if(data){
        this.merchantName = data.staffName
      }else{
        this.merchantName = ""
      }
      const SeachParams = {
        merchantType:"01",
        merchantName:this.merchantName,
        companyId: 0,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      }
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{
}
</style>
