<template>
  <div class="SystemOffer-list">
    <GlobalInfoBar
        title="商家列表"
        content="系统报价商家由平台统一创建，门店商户在报价时需选择合作商家"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
    >      
      <div slot="filter">
        <ResidentFrom />
      </div>

      <ResidentTable @handleDialog="handleDialog" />
    </GlobalChunk>
    <DialogTbale @onClose="onClose" :show="deleteVisible" title="title"  :width='width'
      :columns="tableColumns"
      :data="DialogDataList"
      :currentPage="pageNum"
      :total="total"
      :pageSize="pageSize"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="商家地址" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.address" placement="top">
            <p style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.province}}{{row.city}}{{row.region}}{{row.address}}</p>
          </el-tooltip>
        </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ResidentFrom from './From'
import ResidentTable from './Table'
export default {
  name: 'SystemOffer-list',
  components: {ResidentFrom,ResidentTable},
  data(){
    return{
      width:1200,
      merchantName:"",
      deleteVisible:false,
      title:"关联商家",
      DialogDataList:[],
      total:0,
      pageNum:1,
      pageSize:10,
      companyId:"",
      tableColumns: [
        { label: "商家名称", prop: "companyName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { slotName: "operationAddress" },
        { label: "入驻时间", prop: "createTime" },
        { label: "累计交易（笔）", prop: "recycles" },
        { label: "合作状态",prop: 'applyStatus' },
        { slotName: "operation" },
        
      ]
    }
  },
  computed: {
    dialogParams() {
      return this.$store.state.tagsView.dialogParams
    },
  },
   methods:{
    // 弹窗表格切换页面
    handleCurrentChange(val) {
      this.pageNum = val
      this.companyId = this.dialogParams.companyId
      const params = {
        companyId:this.companyId,
        pageNum:this.pageNum,
        pageSize:this.pageSize
      }
      this.unionList(params)
    },
    //关联商家弹窗
    handleDialog(vals){
      console.log(vals)
      this.deleteVisible = true
      const params = {
          companyId:vals.merchantId,
          pageNum:this.pageNum,
          pageSize:this.pageSize
      }
      this.$store.commit('tagsView/DIALOG_PARAMS', params)
      const listCompanyparams = {
          merchantId:vals.merchantId,
          pageNum:this.pageNum,
          pageSize:this.pageSize
        }
        _api.listCompany(listCompanyparams).then(res=>{
          if(res.code===1){
            this.DialogDataList = res.data.records
            this.total = res.data.total
          }
        })
    },
    //关联商家列表请求
    unionList(params){
      _api.unionMerchantList(params).then(res=>{
        if(res.code===1){
          this.DialogDataList = res.data.records
          this.total = res.data.total
        }
      })
    },
    onClose(){
      this.deleteVisible = false
    },
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-list{
}
</style>
