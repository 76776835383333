<template>
  <div class="GlobalTable">
    <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="tableColumns"
        :data="this.seachDataList"
        :currentPage="page.pageNum"
        :total="this.page.total"
        @handleCurrentChange="handleCurrentChange"
    >
      <!-- <el-table-column label="省市区" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span>{{row.province}}{{row.city}}{{row.region}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="商家简介" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.merchantSimpleIntro" placement="top">
            <p style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.merchantSimpleIntro}}</p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="商家地址" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.fullAddress" placement="top">
            <p style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.fullAddress}}</p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="商家Logo" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span>
            <img :src="row.merchantLogo" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="关联商家" slot="operation" align="center">
        <template slot-scope="{ row }">
            <div @click="handleDialog(row)" class="tableBtn"><span style="border-bottom:1px solid #0981ff">{{row.businessPartnerCount}}</span></div>
        </template>
      </el-table-column>
      <el-table-column width="150" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button
              size="mini"
              type="primary"
              @click="handleBtn(row, 'edit')"
          >编辑资料
          </el-button>
        </template>
      </el-table-column>
    </GlobalTable>
  </div>
</template>

<script>
import MinXinRequest from '@/mixins/request'
import _api from "@/utils/request";
export default {
  inject:['reload'],

  name: 'Table',
  mixins: [MinXinRequest],
  data() {
    return {
      seachDataList:[],
      tableColumns: [
        {label: '商家名称', prop: 'merchantName'},
        // {label: '商家简介', prop: 'merchantSimpleIntro'},
        {slotName: 'operationAddress'},
        // {label: '商家地址', prop: 'merchantAddress'},
        {label: '添加时间', prop: 'createTime'},
        {label: '累计交易（笔）', prop: 'orderCount'},
        {slotName: 'operation'}
      ],
    }
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created(){
    this.handleCurrentChange()
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.handleCurrentChange()
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      console.log('表格切换页面', val)
      const params = {
        merchantType:"01",
        merchantName:this.SeachParams.merchantName,
        companyId: 0,
        pageNum: val,
        pageSize: this.SeachParams.pageSize,
      }
      this.$emit('handleCurrentChange',val)
      _api.ShopTable(params).then(res =>{
        if(res.code===1){
          this.seachDataList = res.data.records
          this.page.pageNum = res.data.current
          this.page.total = res.data.total;
          // this.$store.commit('tagsView/POST_MERCHANTDATA', this.seachDataList)
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      })
    },
    // 表格操作
    handleBtn(row, type) {
      console.log('表格操作', type, row)
      //编辑
      if(type === 'edit'){
        this.$router.push({path:"/SystemOffer/add",query:{type: 'edit',id: row.merchantId}})
      }
    },
    handleDialog(val){
      this.$emit("handleDialog",val)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .account{
    
  }
  .tableBtn{
    color: #0981ff;
    cursor: pointer;
  }
  .handleClass{
    display: inline-block;
    margin-left: 5px;
  }
}
</style>
